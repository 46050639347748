<template>
  <!-- 票券popup -->
  <section
    ref="popCoupons"
    id="popCoupons"
    class="pop coupons"
    @click="close('close')"
  >
    <div class="popBody">
      <a v-if="userCoupons?.length" class="close" @click.stop="disableCouponUse"
        ><u> {{ $t("Not use coupon") }} </u></a
      >
      <a v-else class="close" @click.stop="close('close')">
        <u> {{ $t("Close") }} </u>
      </a>
      <span class="title"> {{ $t("Coupons") }} </span>
      <!-- 票券 -->
      <template v-if="isLoggedIn && !isTicketsLoaded">
        <div class="loading transparent loading--body">
          <div>
            <img src="@/assets/icon/pCoupon.svg" />
            <div></div>
          </div>
        </div>
      </template>
      <template v-else-if="userCoupons.length">
        <div
          v-for="(coupon, index) in userCoupons"
          :key="index"
          :ref="'couponCard-' + index"
          class="coupon--ddp"
        >
          <a class="card__top" @click.stop="useCouponCard(index)">
            <div class="card__info">
              <span class="card__name">{{ coupon.name }}</span>
              <span v-if="coupon.title" class="card__detail">
                {{ coupon.title }}
              </span>
              <span v-if="coupon.useExpirationTs" class="card__detail">
                {{ $t("Expiration Date") }}
                {{ tsToDateTime(coupon.useExpirationTs) }}
              </span>
            </div>
            <img v-if="coupon.images?.length" :src="coupon.images[0]" />
          </a>
          <div class="dash"></div>
          <span class="card__description">
            <div v-html="coupon.description"></div>
            <div v-html="coupon.issuedBy?.restriction"></div>
          </span>
          <a class="card__action" @click.stop="onClickMore($event, index)">
            <span> {{ $t("See more") }} </span>
            <font-awesome-icon :ref="'couponCardArrow-' + index" class="card-arrow" icon="fa-solid fa-chevron-down" />
          </a>
          <div
            class="card__tag"
            v-if="coupon.ticketId === currentSelectCoupon?.ticketId"
          >
            <span> {{ $t("Selected") }} </span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="coupons--none">
          <img src="@/assets/icon/pCoupon.svg" />
          <span>{{ $t("No coupons available") }}</span>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import { fetchCouponQuotation } from "@/apis/fetchData";

export default {
  name: "PopupCoupons",
  props: {},
  data() {
    return {
      // show: true,
      currentTab: "coupon",
      selectedCoupon: null,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      isTicketsLoaded: (state) => state.isTicketsLoaded,
      orderInfo: (state) => state.orderInfo,
    }),
    ...mapGetters({
      userCoupons: "getUserCoupons",
      orderAmount: "getAmount",
      userId: "getUserId",
      merchantId: "getMerchantId",
      currentSelectCoupon: "getSelectedCoupon",
      shopId: "getShopId",
      thirdPartyMember: "getThirdPartyMemberShip"
    }),
    partnerCode () {
      return this.orderInfo?.member?.thirdPartyPartnerCode || this.thirdPartyMember?.partnerCode;
    },
  },
  watch: {},
  mounted() {
    this.popup();
  },
  methods: {
    close(eventName) {
      const self = this;
      // 有其他close event
      // this.$store.commit("updateIsQuoting", true);
      if (eventName === "select") {
        console.log("[PopupCoupons] select to use coupon");
        // 報價有可能失敗，所以要例外處理。
        this.$store.commit("updateIsQuoting", true);
        this.$store.commit("updateSelectedCoupon", this.selectedCoupon);
        this.getCouponQuotation();
      } else if (eventName === "disabled") {
        console.log("[PopupCoupons] disable coupon use.");
        this.$store.commit("updateIsQuoting", true);
        this.$store.commit("updateDiscounts", []);
        this.$store.commit("updateSelectedCoupon", null);
        this.$store.commit("updateIsQuoting", false);
      }
      const pop = this.$refs.popCoupons; // document.getElementById('popError');
      pop.children[0].style.animationName = "popdown";
      setTimeout(function () {
        document.querySelector("body").setAttribute("style", "overflow:auto");
        self.$emit("close", eventName);
      }, 250);
    },
    popup() {
      const target = this.$refs.popCoupons;
      target.style.display = "flex";
      document.querySelector("body").setAttribute("style", "overflow:hidden");
    },
    tsToDateTime(timestamp) {
      return moment.unix(timestamp).format("YYYY/MM/DD HH:mm");
    },
    openCouponCard($event, couponIndex) {
      // console.log('openCouponCard' + couponIndex);
      let cards = document.getElementsByClassName("coupon__card");
      const target = `couponCard-${couponIndex}`;
      let cardElem = this.$refs[target][0];
      // console.log('cardElem', cardElem)
      const isOpened = cardElem.style.animationName === "openCard";
      for (let i = 0; i < cards.length; i++) {
        let card = cards.item(i);
        card.style.animationDelay = "0s";
        if (card.style.animationName === "openCard")
          card.style.animationName = "closeCard";
        card.style.height = "90px";
        card.style.minHeight = "90px";
      }

      if (isOpened) {
        cardElem.style.animationName = "closeCard";
        cardElem.style.height = "90px";
        cardElem.style.minHeight = "90px";
      } else {
        cardElem.style.animationName = "openCard";
        cardElem.style.height = "400px";
        cardElem.style.minHeight = "400px";
        setTimeout(function () {
          cardElem.scrollIntoView({ behavior: "smooth" });
        }, 300);
      }
    },
    onClickMore($event, couponIndex)  {
      const cards = document.getElementsByClassName("coupon--ddp");
      const target = `couponCard-${couponIndex}`;
      const targetArrow = `couponCardArrow-${couponIndex}`;
      let cardElem = this.$refs[target][0];
      let arrowElem = this.$refs[targetArrow][0];
      var isOpened = cardElem.style.animationName === "cardMore";
      for (var i = 0; i < cards.length; i++) {
        var card = cards.item(i);
        card.style.animationDelay = "0s";
        if (card.style.animationName == "cardMore")
          card.style.animationName = "cardLess";
        card.style.height = "172px";
        card.style.minHeight = "172px";
      }
      if (isOpened) {
        arrowElem.style.animationName = "arrowDown";
        cardElem.style.animationName = "cardLess";
        cardElem.style.height = "172px";
        cardElem.style.minHeight = "172px";
        setTimeout(function () {
          arrowElem.style.transform = "rotate(0deg)";
        }, 500);
      } else {
        arrowElem.style.animationName = "arrowUp";
        cardElem.style.animationName = "cardMore";
        cardElem.style.height = "300px";
        cardElem.style.minHeight = "300px";
        setTimeout(function () {
          arrowElem.style.transform = "rotate(180deg)";
        }, 500);
        setTimeout(function () {
          cardElem.scrollIntoView({ behavior: "smooth" });
        }, 300);
      }
    },
    useCouponCard(couponIndex) {
      this.selectedCoupon = Object.assign({}, this.userCoupons[couponIndex]);
      this.close("select");
    },
    disableCouponUse() {
      this.close("disabled");
    },
    getCouponQuotation() {
      console.log('[PopupCoupons] getCouponQuotation:' + JSON.stringify(this.selectedCoupon));
      // let discount =this.orderAmount - this.selectedCoupon.quotation;
      // console.log('[PopupCoupons] getCouponQuotation:', this.orderAmount, this.selectedCoupon.quotation, discount)
      // this.$store.commit('updateDiscounts', [{
      //   name: this.selectedCoupon.name,
      //   productId: this.selectedCoupon.productId,
      //   amount: discount
      // }]);
      let queryString = {
        "amount-to-quote": this.orderAmount,
        "shop-id": this.shopId,
        "provider": this.selectedCoupon.provider || ""
      };
      fetchCouponQuotation(
        this.partnerCode,
        this.merchantId,
        this.userId,
        this.selectedCoupon.ticketId,
        queryString
      )
        .then((response) => {
          let discount = this.orderAmount - response.data.quotation;
          this.$store.commit("updateDiscounts", [
            {
              name: this.selectedCoupon.name,
              provider: this.selectedCoupon.provider || "",
              productId: this.selectedCoupon.productId,
              ticketId: this.selectedCoupon.ticketId,
              amount: discount,
            },
          ]);
        })
        .catch((e) => {
          console.log("failed to get coupon quotation", e);
          this.$store.commit("updateDiscounts", []);
          this.$store.commit("updateSelectedCoupon", null);
          let errorConfig = {
            type: "error",
            message: this.$i18n.t("errors['Quotation failed']"),
          };

          let apiErrorCode = e.response?.data?.error?.code;
          if (e.response?.data?.error?.message) {
            errorConfig.message = e.response.data.error.message;
          }

          if (apiErrorCode) {
            errorConfig.message = `[${apiErrorCode}] ${errorConfig.message}`;
          }

          this.$emit('error', errorConfig)
        })
        .finally(() => {
          this.$store.commit("updateIsQuoting", false)
        });
    },
  },
};
</script>
