import store from "@/store";

export default {
  name: "DDPG",
  data() {
    return {
      sdk: null,
      sdkInitialized: false,
      supportedPaymentMethods: [],
    };
  },

  created() {},

  methods: {
    initDDPGSDK(config) {
      // sdk = new DDPGSdk({
      //   mode: 'sandbox',
      //   tappayAppId: 12867,
      //   tappayAppKey: 'app_8rd2JX9GLGqoNPZPo07DI9XJP1n2X6klgOJZj5Y8eHggDTIjm44fR5UJCeJe',
      //   merchantId: 'D_pxmE8ML12'
      // });
      console.log("init DDPG SDK");
      this.sdk = new window.DDPGSdk(config);
      // Demo: how to fetch list of supported payment methods of the merchant.

      // Init TapPay
      this.sdk.init();

      this.$nextTick(() => {
        this.checkIframe();
      });  

      // Init card-number form validation via SDK.
      // this.setTappayCardInfo();
      // this.sdk.cardFormSetup({
      //   fields: {
      //     number: {
      //       element: "#input-card-number",
      //       placeholder: "**** **** **** ****",
      //     },
      //     expirationDate: {
      //       element: document.getElementById("input-exp-date"),
      //       placeholder: "MM / YY",
      //     },
      //     ccv: {
      //       element: "#input-ccv",
      //       placeholder: "CVC / CVV",
      //     },
      //   },
      //   // styles: {
      //   //   'input': {
      //   //     'width': '100%',
      //   //     'text-align': 'end',
      //   //     'font-size': 'clamp(1.2rem, 5vw, 1.3rem)',
      //   //     'margin': '20px',
      //   //     'padding': '20px'
      //   //   },
      //   //   'input.ccv': {
      //   //     'width': '100%',
      //   //     'text-align': 'end',
      //   //     'font-size': 'clamp(1.2rem, 5vw, 1.3rem)',
      //   //     'margin': '5px 0',
      //   //     'padding': '5px'
      //   //   },
      //   //   'input.expiration-date': {
      //   //     'width': '100%',
      //   //     'text-align': 'end',
      //   //     'font-size': 'clamp(1.2rem, 5vw, 1.3rem)',
      //   //     'margin': '5px 0',
      //   //     'padding': '5px'
      //   //   },
      //   //   'input.card-number': {
      //   //      'width': '100%',
      //   //     'text-align': 'end',
      //   //     'font-size': 'clamp(1.2rem, 5vw, 1.3rem)',
      //   //     'margin': '5px 0',
      //   //     'padding': '5px'
      //   //   },
      //   // }
      // });
      // 初始化信用卡表單
      // this.initCardFormSetup();

      this.getSupportedPaymentMethods();
      // // If the browser does not support the error, this will catch error.
      // this.sdk.checkPaymentAvailability('tappay', 'direct')
      // .then((res) => {
      //   console.log('checkPaymentAvailability:', res)
      // })
      // .catch(err => {
      //   console.error(`TapPay Direct (credit card) is not supported.`, err);
      // });
    },
    checkIframe() {
      const methodsContainer = this.$refs.methodsContainer;
      
      if (!methodsContainer || !(methodsContainer.$el instanceof HTMLElement)) {
        console.error('methodsContainer ref is not a valid DOM element!');
        return;
      }

      console.log("current time:", new Date().toUTCString())
      // Start an interval to check for the iframe
      const checkIframeInterval = setInterval(() => {
        const iframe =  methodsContainer.$el.querySelector('iframe');
        console.log('iframe:', iframe);
        if (iframe) {
          console.log('Iframe has been appended');
          console.log("current time end:", new Date().toUTCString())
          clearInterval(checkIframeInterval); // Stop checking if iframe is found
        } else {
          console.log('Iframe does not exist');
          this.setTappayCardInfo();
          this.initCardFormSetup();
        }
      }, 100); // Check every 100ms

      // Set a timeout to stop checking after 2 seconds
      setTimeout(() => {
        if (checkIframeInterval) {
          clearInterval(checkIframeInterval); // Stop the interval after 2 seconds
          console.log('Stopped checking after 2 seconds');
        }
      }, 2000); // 2 seconds timeout
    },
    getSupportedPaymentMethods() {
      // Demo: how to fetch list of supported payment methods of the merchant.
      this.sdk
        .getSupportedPaymentMethods()
        .then((result) => {
          console.log(
            "List of supported payment methods:" + JSON.stringify(result)
          );
          if (Array.isArray(result)) {
            this.supportedPaymentMethods = result;
          } else {
            // this.supportedPaymentMethods = result?.methods || [];
            this.supportedPaymentMethods =
              result && result.methods ? result.methods : [];
          }
          let pros = [];
          this.supportedPaymentMethods.forEach((method) => {
            pros.push(
              this.checkPaymentAvailability(
                method.paymentService,
                method.paymentType
              )
            );
          });
          if (pros.length) {
            Promise.all(pros)
              .then((res) => {
                this.supportedPaymentMethods.map((obj, index) => {
                  obj.isBrowserSupported = res[index];
                  return obj;
                });
              })
              .catch((err) => {
                console.log("getSupportedPaymentMethods", err);
              })
              .finally(() => {
                store.commit(
                  "updateSupportedPaymentMethods",
                  this.supportedPaymentMethods
                );
                console.log(
                  "getSupportedPaymentMethods promise done.",
                  JSON.stringify(this.supportedPaymentMethods)
                );
              });
          }
          // store.commit('updateSupportedPaymentMethods', result);
        })
        .catch((e) => {
          console.log("getSupportedPaymentMethods failed", e);
        })
        .finally(() => {
          this.sdkInitialized = true;
          store.commit('updateSDKInitState', true)
        });
    },
    checkPaymentAvailability(service, type) {
      // If the browser does not support the error, this will catch error.
      return this.sdk
        .checkPaymentAvailability(service, type)
        .then((res) => {
          console.log("checkPaymentAvailability:", res);
          return true;
        })
        .catch((err) => {
          console.error(`${service}-${type} is not supported.`, err);
          return false;
        });
    },
    getPayIndex(payConfig) {
      console.log("[mixins]: getPayIndex:" + JSON.stringify(payConfig));
      return this.sdk
        .getIndex(payConfig)
        .then((index) => {
          console.log(`Got DDPG index`, index);
          return index;
          // Use DDPG API to proceed to pay.
        })
        .catch((e) => {
          console.error("getPayIndex failed:", e);
          throw e;
        });
    },
    pay(payConfig) {
      // sdk.getIndex({
      //   paymentService: 'tappay',
      //   paymentType: 'direct',
      //   amount: 10
      // })
      // .then(index => {
      //   console.log(`Got DDPG index`, index);

      //   // Use DDPG API to proceed to pay.
      //   $.ajax({
      //     type: 'POST',
      //     url: 'https://qrpaypg-staging.wifiotg.com/ddpg/pay',
      //     dataType: 'json',
      //     contentType: "application/json;charset=utf-8",
      //     data: JSON.stringify({
      //       index: index,
      //       merchantId: 'D_pxmE8ML12',
      //       merchantOrderId: 'EX' + Date.now(),
      //       billingInfo: {
      //         name: 'Test Man',
      //         phone: '0987654321',
      //         email: 'test@iiot.io'
      //       },
      //       orderInfo: {
      //         amount: 10,
      //         description: 'A example payment.'
      //       },
      //       resultUrl: 'https://www.iiot.io',
      //       cancelUrl: 'https://quickclick.cc',
      //       notifyUrl: 'https://58f2656071645053fd176ea8d86ff156.m.pipedream.net'
      //     }),
      //     success: function(returnData) {
      //       console.error(`DDPG pay succeeded`, returnData);
      //     },
      //     error: function(xhr, ajaxOptions, thrownError) {
      //       console.error(`DDPG says it's failed`, thrownError);
      //     }
      //   });
      // });
      // Go get an "index" of DDPG.
      this.sdk.getIndex(payConfig).then((index) => {
        console.log(`Got DDPG index`, index);
        // Use DDPG API to proceed to pay.
      });
    },
    initApplePay(service, amount) {
      if (service === "cardpay") {
        return this.sdk.CardPayInitApplePay(amount).catch((e) => {
          console.log("[DDPG] initApplePay (CARDPAY) failed", e);
          throw e;
        });
      } else {
        return this.sdk.TapPayInitApplePay(amount).catch((e) => {
          console.log("[DDPG] initApplePay (TAPPAY) failed", e);
          throw e;
        });
      }
    },
    setTappayCardInfo() {
      // Init card-number form validation via SDK.
      return this.sdk.cardFormSetup({
        fields: {
          number: {
            element: "#input-card-number",
            placeholder: "**** **** **** ****",
          },
          expirationDate: {
            element: document.getElementById("input-exp-date"),
            placeholder: "MM / YY",
          },
          ccv: {
            element: "#input-ccv",
            placeholder: "CVC / CVV",
          },
        },
      });
    },
    setCardpayCardInfo(data) {
      return this.sdk.CardPaySetCardInfo(data);
    },
    getCardFormStatus(service) {
      let result = null;
      try {
        if (service === "cardpay") {
          result = this.sdk.CardPayGetCardFormStatus();
          console.log(
            "[DDPG] cardpay getCardFormStatus: " + JSON.stringify(result)
          );
          // return (result?.isCardNoValid && result?.isExpiryValid);
          return result && result.isCardNoValid && result.isExpiryValid;
        } else {
          // return this.sdk.TapPayGetCardFormStatus();
          result = this.sdk.TapPayGetCardFormStatus();
          console.log(
            "[DDPG] tappay getCardFormStatus: " + JSON.stringify(result)
          );
          return result.canGetPrime; // true: OK: false: fail
        }
      } catch (error) {
        console.error("[DDPG] getCardFormStatus exception", error);
        return false;
      }
    },
    initCardFormSetup(elementId) {
      console.log('init cardpay credit card form;' + elementId);
      const elem = elementId || "#cif";
      this.sdk.cardFormSetup(
        {
          provider: "cardpay",
          selector: elem,
          //color、font-size、border、margin、padding、border-radius
          styles: {
            input: {
              color: "black",
              "font-size": "clamp(1.2rem, 5vw, 1.3rem)",
              margin: "5px 0",
              padding: "5px",
              "border-radius": "5px",
            },
          },
        },
        (update) => {
          console.warn(`Cardpay card info form updated`, update);
        }
      );
    },
    setMemberId(memberId) {
      console.log('[DDPG] setMemberId: ' + memberId);
      this.sdk.setMemberId(memberId);
      this.initCardFormSetup();
    }
  },
};
