<template>
  <section class="type">
    <div class="top">
      <span class="title"> {{ $t("Choose A Payment Method") }} </span>
      <a v-if="hasSystemNotice" @click="showNotice">
        <font-awesome-icon icon="fa-solid fa-triangle-exclamation" size="lg" />
      </a>
    </div>
    <div class="payment__method__reminder" v-if="!availablePaymentMethods"> 
      <span> {{ $t("No payment method") }} </span>
    </div>
    <!-- 理論上只會有一個信用卡 -->
    <div
      class="credit"
      v-show="paymentMethodsByCards"
      @click.stop="onCreditCardClick(paymentMethodsByCards)"
      :class="{
        active:
          selectedMethod && paymentMethodsByCards && 
          paymentMethodsByCards.paymentServiceId ===
            selectedMethod.paymentServiceId &&
          (userCards?.length === 0 || !showCardDetail),
      }"
    >
      <div class="credit__top">
        <a>
          <img v-if="selectedCard?.icon" :src="selectedCard?.icon" />
          <img v-else src="@/assets/icon/pCredit.svg" />
          <span class="credit__title"> {{ $t("Credit Card") }} </span>
        </a>
        <a>
          <span> {{ selectedCard?.cardNo }}</span>
          <div class="credit__dropdown" @click.stop="expandCardSection">
            <font-awesome-icon
              v-if="showCardDetail"
              icon="fa-solid fa-square-caret-up"
              size="lg"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-square-caret-down"
              size="lg"
            />
          </div>
        </a>
      </div>
      <div class="credit__cards" v-show="showCardDetail">
        <a
          v-for="(card, index) in userCards"
          :key="index"
          class="credit__card"
          :class="{
            active: card?.cardToken === selectedCard?.cardToken,
          }"
          @click="onCardSelected(paymentMethodsByCards, card)"
        >
          <img :src="card.icon" />
          <span> {{ card.cardNo }} </span>
        </a>
        <a
          v-if="userCards?.length"
          class="credit__card"
          :class="{
            active: !selectedCard?.cardToken,
          }"
          @click.stop="onCardSelected(paymentMethodsByCards, undefined)"
        >
          <font-awesome-icon icon="fa-solid fa-plus" size="lg" />
          <span> {{ $t("Enter Credit Card") }} </span>
        </a>
      </div>
      <!-- 信用卡表單-->
      <!-- tappay card form -->
      <div v-show="showTappayCardForm && showCardDetail" class="credit__form">
        <div id="input-card-number" class="tpfield"></div>
        <div id="input-exp-date" class="tpfield"></div>
        <div id="input-ccv" class="tpfield"></div>
      </div>
      <!-- cardpay card form -->
      <div v-show="showCardpayCardForm && showCardDetail" class="credit__form">
        <div id="cif" class="cardpay"></div>
      </div>
      <!-- <div class="credit__form">
        <input type="text" placeholder="卡號" value="" />
        <input type="text" placeholder="到期日" value="" />
        <input type="text" placeholder="CVV" value="" />
        <label class="check">
          <input type="checkbox" name="checkbox3" checked />
          <span class="checkmark checkbox"></span>
          <span>儲存信用卡</span>
        </label>
      </div> -->
    </div>
    <div class="center" v-for="i in rowCount" :key="i">
      <a
        v-for="(method, index) in supportedPaymentMethods.slice(
          (i - 1) * itemsPerRow,
          i * itemsPerRow
        )"
        :key="index"
        v-show="method.isEnabled"
        @click="onSelectPaymentMethod(method)"
        :class="{
          active:
            selectedMethod &&
            method.paymentServiceId === selectedMethod.paymentServiceId,
        }"
      >
        <!-- class="active" -->
        <font-awesome-icon
          v-if="method.paymentService === 'offline'"
          icon="fa-solid fa-money-bill-wave"
        />
        <img v-else-if="method.icon" :src="method.icon" />
        <font-awesome-icon v-else icon="fa-solid fa-credit-card" />
        <img v-if="!method.isBrowserSupported" src="@/assets/icon/error.svg" class="error__icon">

        <span v-if="!method.icon">{{ method.label }} </span>
        <!-- <div>
          <span>{{ method.label }}</span>
          <span v-if="showPaymentName">{{ method.name }}</span>
        </div> -->
        <font-awesome-icon
          v-if="method.paymentService !== 'offline' && !method.icon"
          icon="fa-solid fa-chevron-right"
          size="sm"
        />
      </a>
    </div>
  </section>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { fetchSavedCards } from "@/apis/fetchData";

export default {
  name: "AvailablePaymentMethods",
  props: {
    storeCode: String,
    hasSystemNotice: Boolean,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      // merchantId: process.env.VUE_APP_MERCHANT_ID,
      itemsPerRow: 2,
      selectedMethod: null,
      showTappayCardForm: false,
      showCardpayCardForm: false,
      showCardDetail: false,
      defaultCard: undefined,
    };
  },
  created: function () {},
  mounted: function () {
    console.log("[AvailablePaymentMethods] mounted success!");
    if (this.userId) {
      this.getUserCards();
    }
    if (this.availablePaymentMethods === 1) {
      if (this.paymentMethodsByCards) {
        this.onCreditCardClick(this.paymentMethodsByCards);
      } else if (this.supportedPaymentMethods.length) {
        this.onSelectPaymentMethod(this.supportedPaymentMethods[0]);
      }
    }
    // if (this.supportedPaymentMethods.length) {
    //   this.onSelectPaymentMethod(this.supportedPaymentMethods[0]);
    // }
  },
  computed: {
    ...mapState(["user", "orderInfo"]),
    ...mapGetters({
      paymentMethods: "getSupportedPaymentMethods",
      userCards: "getUserCards",
      userId: "getUserId",
      merchantId: "getMerchantId",
      selectedCard: "getSelectedCard",
      payableAmount: "getPayableAmount",
      portalInfo: "portal/getPortalInfo"
    }),
    showPaymentName() {
      return false;
    },
    excludePaymentMethods() {
      const excludePaymentMethods = this.portalInfo?.excludePaymentMethods || [];
      let exclusions = [];
      // for legacy CD
      if (this.orderInfo.mode === "cd") {
        exclusions = [
          "linepay-linepay",
          "tappay-jko",
          "jko-jko",
          "tappay-line",
        ];
      }
      excludePaymentMethods.forEach(pm => {
        exclusions.push(`${pm.service}-${pm.type}`);
      })  
      return exclusions;
    },
    // 指定支付
    allowedPaymentMethods() {
      const _allowedPaymentMethods = this.orderInfo?.allowedPaymentMethods || [];
      let allowed = [];
     
      _allowedPaymentMethods.forEach(pm => {
        allowed.push(`${pm.service}-${pm.type}`);
      })  
      return allowed;
    },
    // 除信用卡外的其它支付
    supportedPaymentMethods() {
      // CD要擋line pay, jko
      //return this.paymentMethods.filter(method => method.isBrowserSupported && method.isEnabled);
      let methods = [];
      let types = ["direct", "credit"];

      this.paymentMethods.forEach((method) => {
        // 根據 portal excludePaymentMethods 把不支援的支付方式隱藏
        const payment = `${method.paymentService}-${method.paymentType}`;
        if (
          !this.excludePaymentMethods.includes(payment) &&
          !types.includes(method.paymentType) &&
          method.isEnabled && 
          !method.isSystemDisabled
        ) {
          methods.push(method);
        }
        // if (this.orderInfo.mode === "cd") {
        //   const exclusions = [
        //     "linepay-linepay",
        //     "tappay-jko",
        //     "jko-jko",
        //     "tappay-line",
        //   ];
        //   const payment = `${method.paymentService}-${method.paymentType}`;
        //   if (
        //     !exclusions.includes(payment) &&
        //     !types.includes(method.paymentType) &&
        //     method.isBrowserSupported &&
        //     method.isEnabled && 
        //     !method.isSystemDisabled
        //   ) {
        //     methods.push(method);
        //   }
        // } else if (
        //   this.orderInfo.mode !== "cd" &&
        //   !types.includes(method.paymentType) &&
        //   method.isBrowserSupported &&
        //   method.isEnabled && 
        //   !method.isSystemDisabled
        // ) {
        //   methods.push(method);
        // }
      });
      if (this.allowOnsitePayment) {
        methods.push({
          "name": "現場支付",
          "paymentServiceId": null,
          "paymentService": "offline",
          "paymentType": "offline",
          "isEnabled": true,
          "sessionlessOnly": false,
          "isBrowserSupported": true,
          "icon": "",
          "iconW": "",
          "isSystemDisabled": false,
          "label": this.$i18n.t('paymentMethods.offline')
        });
      }

      // 若有指定支付，把指定支付以外的方式隱藏
      if (this.allowedPaymentMethods.length) {
        methods = methods.filter(m => {
          return this.allowedPaymentMethods.includes(`${m.paymentService}-${m.paymentType}`) 
        });
      }
      return methods;
    },
    // 信用卡支付
    paymentMethodsByCards() {
      let methods = [];
      let types = ["direct", "credit"];
      this.paymentMethods.forEach((method) => {
        const payment = `${method.paymentService}-${method.paymentType}`;
        if (
          !this.excludePaymentMethods.includes(payment) &&
          types.includes(method.paymentType) &&
          method.isEnabled && 
          !method.isSystemDisabled
        ) {
          methods.push(method);
          if (method.paymentService === "cardpay") {
            this.showCardpayCardForm = true;
            this.showTappayCardForm = false;
          } else if (method.paymentService === "tappay") {
            this.showCardpayCardForm = false;
            this.showTappayCardForm = true;
          }
        }
      });
      console.log("paymentMethodsByCards:" + JSON.stringify(methods));
      // 若有指定支付，把指定支付以外的方式隱藏
      if (this.allowedPaymentMethods.length) {
        methods = methods.filter(m => this.allowedPaymentMethods.includes(`${m.paymentService}-${m.paymentType}`));
      }
      return methods.length ? methods[0] : null;
      // return methods;
    },
    rowCount() {
      //return this.supportedPaymentMethods?.length ? Math.ceil(this.supportedPaymentMethods.length / this.itemsPerRow) : 0;
      return this.supportedPaymentMethods && this.supportedPaymentMethods.length
        ? Math.ceil(this.supportedPaymentMethods.length / this.itemsPerRow)
        : 0;
    },
    availablePaymentMethods () {
      let supportedMethods = this.supportedPaymentMethods?.length || 0;
      let supportedCardMethods = this.paymentMethodsByCards ? 1 : 0;
      return supportedMethods + supportedCardMethods;
    },
    allowOnsitePayment () {
      if (this.portalInfo?.allowedNonOnlinePaymentModes?.length && this.orderInfo?.mode) {
        return this.portalInfo.allowedNonOnlinePaymentModes.includes(this.orderInfo.mode);
      } else {
        return false;
      }
    }
  },
  watch: {
    userId: {
      handler(newVal) {
        if (newVal) {
          this.getUserCards();
        }
      },
    },
    // 只有一種付款方式時直接選取
    availablePaymentMethods(newVal) {
      console.log('watch availablePaymentMethods:' + newVal, this.supportedPaymentMethods?.length, this.paymentMethodsByCards)
      if (newVal === 1) {
        if (this.paymentMethodsByCards) {
          this.onCreditCardClick(this.paymentMethodsByCards);
        } else if (this.supportedPaymentMethods.length) {
          this.onSelectPaymentMethod(this.supportedPaymentMethods[0]);
        }
      }
    },
    // supportedPaymentMethods(newVal) {
    //   if (newVal.length === 1 && !this.paymentMethodsByCards && this.allowOnsitePayment) {
    //     this.onSelectPaymentMethod(this.supportedPaymentMethods[0]);
    //   }
    // },
  },
  methods: {
    getUserCards() {
      fetchSavedCards(this.merchantId, this.userId)
        .then((response) => {
          console.log("done fetchSavedCards: " + JSON.stringify(response));
          if (response.data) {
            this.$store.commit("updateUserCards", response.data);
            this.setDefaultCard();
          } else {
            this.$store.commit("updateUserCards", []);
          }
        })
        .catch((e) => {
          console.log("fetchSavedCards failed:", e);
        });
    },
    setDefaultCard() {
      const _defaultCard = this.userCards.find((card) => card.isDefault);
      let _lastestUsed = null;
      _lastestUsed = this.userCards.reduce((prev, current) => {
        if (prev?.latestUsedAt && current?.latestUsedAt) {
          return prev.latestUsedAt > current.latestUsedAt ? prev : current;
        } else {
          return prev;
        }
      });
      console.log("setDefaultCard:", _defaultCard, _lastestUsed);
      this.defaultCard = _defaultCard || _lastestUsed;
      // this.onCardSelected(this.paymentMethodsByCards, this.defaultCard);
      this.$store.commit("updateSelectedCard", this.defaultCard);
      this.showCardpayCardForm = false;
      this.showTappayCardForm = false;
    },
    onSelectPaymentMethod(method) {
      if (!method.isBrowserSupported) {
        console.log('Browser not support.');
        this.$emit("selected", {
          method: method,
        });
        return;
      }
      this.selectedMethod = { ...method };
      this.showCardDetail = false;

      // let types = ["direct", "credit"];
      // let showTappayCardForm = false;
      // let showCardpayCardForm = false;

      // 新版信用卡另外處理 onCardSelected
      // let foundTappayCredit = null;
      // let foundCardpayCredit = null;
      // if (method.paymentType) {
      //   foundTappayCredit = types.find((t) => t === method.paymentType);
      //   foundCardpayCredit = method.paymentType === "credit";
      // }
      // if (method.paymentService === "tappay" && foundTappayCredit) {
      //   showTappayCardForm = true;
      // }
      // if (method.paymentService === "cardpay" && foundCardpayCredit) {
      //   showCardpayCardForm = true;
      // }
      this.$emit("selected", {
        method: method,
      });
    },
    onCreditCardClick(method) {
      console.log("onCreditCardClick", JSON.stringify(this.selectedCard));
      this.showCardDetail = true;
      const _method = method || this.paymentMethodsByCards;
      this.selectedMethod = { ..._method };

      if (this.userCards.length && this.selectedCard) {
        this.showTappayCardForm = false;
        this.showCardpayCardForm = false;
      }

      // 送出信用卡支付方式，但不用帶顯示pop資訊
      this.$emit("selected", {
        method: this.selectedMethod,
        showCardDetail: true
      });
    },
    onCardSelected(method, card) {
      console.log("onCardSelected:", card);
      this.selectedMethod = { ...method };
      // this.showTappayCardForm = false;
      // this.showCardpayCardForm = false;
      this.$store.commit("updateSelectedCard", card);

      if (!card) {
        // const self = this;
        this.$nextTick(function () {
          let types = ["direct", "credit"];
          if (
            method.paymentService === "tappay" &&
            types.includes(method.paymentType)
          ) {
            this.showTappayCardForm = true;
          } else if (
            method.paymentService === "cardpay" &&
            method.paymentType === "credit"
          ) {
            this.showCardpayCardForm = true;
          }
        });
      }
    },
    expandCardSection() {
      this.showCardDetail = !this.showCardDetail;
      // console.log("showDetail:" + this.showCardDetail);

      if (
        this.showCardDetail &&
        this.paymentMethodsByCards?.paymentServiceId !==
          this.selectedMethod?.paymentServiceId
      ) {
        this.onCreditCardClick();
        // this.onCardSelected(this.paymentMethodsByCards, undefined);
      }
    },
    // debugUse(e) {
    //   console.log("====debug====", e);
    // },
    showNotice() {
      this.$emit("noticed");
    },
  },
};
</script>
<style scoped>
div.credit__form .tpfield {
  width: 100% !important;
  height: 30px !important;
  border-bottom: 1px solid var(--cG4) !important;
  font-size: var(--fL) !important;
}
div.credit__form .tpfield iframe {
  height: 30px !important;
}

div.credit__form .cardpay iframe {
  width: 100%;
  height: fit-content !important;
}
div.credit__form .cardpay iframe html {
  overflow: hidden;
}
div.credit__form .cardpay iframe html body {
  margin: 0;
}
div.credit__form .cardpay iframe html body input {
  width: 100%;
  margin: 0 0 20px 0 !important;
  border: solid var(--cG4) !important;
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  font-size: var(--fL);
  box-sizing: border-box;
}

.payment__method__reminder span {
  font-size: var(--fS);
  /* text-align: center; */
  color: var(--c4);
}
</style>
