<template>
  <section class="discount" v-show="showDiscount">
    <div class="top">
      <span class="title"> {{ $t("Discount") }} </span>
    </div>
    <a class="discount__coupon" :class="{'discount--only': noPaymentServices}" @click="showCoupons = true">
      <img src="@/assets/icon/pCoupon.svg" />
      <span class="discount__title"> {{ $t("Coupons") }} </span>
      <span v-if="!isTicketsLoaded" class="discount__name discount__name--none"> {{ $t("Loading") }} </span>
      <span v-else-if="userCoupons?.length && selectedCoupon" class="discount__name discount__name--active">
        {{ selectedCoupon.name }}
      </span>
      <span v-else class="discount__name" :class="{'discount__name--none': !userCoupons?.length}">
        {{ $tc("numberOfCoupons",  userCoupons?.length || 0, {total: userCoupons?.length || 0}) }}
      </span>
      
      <!-- <span v-else class="discount__name discount__name--none">  {{ $tc("numberOfCoupons",  userCoupons.length || 0, {total: userCoupons.length || 0}) }} </span> -->
      <font-awesome-icon icon="fa-solid fa-chevron-right" size="lg" />
    </a>
    <a v-if="shopSupportedPoints?.length" class="discount__point" :class="{'discount--only': noPaymentServices}" @click="showPoints = true">
      <img
        v-if="selectedPoint && selectedPoint.icon"
        :src="selectedPoint.icon"
      />
      <img
        v-else-if="selectedPoint && selectedPoint.pointLogo"
        :src="selectedPoint.pointLogo"
      />
      <img v-else src="@/assets/icon/pPoint.svg" />
      <span class="discount__title"> {{ $t("Points") }} </span>
      <span v-if="!isPointsLoaded" class="discount__name discount__name--none"> {{ $t("Loading") }} </span>
      <span v-else-if="selectedPoint" class="discount__name discount__name--active">
        {{ selectedPoint.pointName }} {{ selectedPoint.usingPoints }}
      </span>
      <span v-else class="discount__name" :class="{'discount__name--none': !availablePointTypes?.length}">
        {{ $tc("numberOfPoints",  availablePointTypes?.length || 0, {total: availablePointTypes?.length || 0}) }}
      </span>
      <font-awesome-icon icon="fa-solid fa-chevron-right" size="lg" />
    </a>

    <!-- 點數popup -->
    <PopupPoints v-if="showPoints" @close="onPointsPopupClose" />

    <!-- 優惠券popup -->
    <PopupCoupons v-if="showCoupons" @close="onCouponsPopupClose" @error="onError"/>
  </section>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import {
  fetchSupportedPoints,
  fetchUserPoints,
  fetchUserCoupons,
} from "@/apis/fetchData";
import PopupPoints from "@/components/PopupPoints.vue";
import PopupCoupons from "@/components/PopupCoupons.vue";

export default {
  name: "Discounts",
  components: {
    PopupPoints,
    PopupCoupons,
  },
  props: {
    noPaymentServices: Boolean
    // storeCode: String,
    // showToggleAll: Boolean,
    // pointAmount: Number,
    // toggleAll: Boolean,
    // inputFocus: Boolean,
    // disableInputBox: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      showPoints: false,
      showCoupons: false,
    };
  },
  created: function () {},
  mounted: function () {
    console.log("success!");
    // this.amount = this.pointAmount;
    this.init();
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
      isTicketsLoaded: (state) => state.isTicketsLoaded,
      isPointsLoaded: (state) => state.isPointsLoaded,
      orderInfo: (state) => state.orderInfo,
    }),
    ...mapGetters({
      shopId: "getShopId",
      userId: "getUserId",
      memberId: "getMemberId",
      availablePointTypes: "getAvailablePointTypes",
      merchantId: "getMerchantId",
      orderAmount: "getAmount", // 訂單金額
      selectedPoint: "getSelectedPoint",
      selectedCoupon: "getSelectedCoupon",
      ticketDiscount: "getTicketDiscounts",
      shopSupportedPoints: "getShopSupportedPointTypes",
      userCoupons: "getUserCoupons",
      thirdPartyMember: "getThirdPartyMemberShip"
    }),
    partnerCode () {
      return this.orderInfo?.member?.thirdPartyPartnerCode || this.thirdPartyMember?.partnerCode;
    },
    showDiscount () {
      return this.isLoggedIn || this.thirdPartyMember?.uuid;
    }
  },
  watch: {
    userId: {
      handler(newVal) {
        if (newVal) {
          this.initUserPointAndCoupons();
          // this.getUserPoints();
          // this.getUserCoupons();
        }
      },
    },
    ticketDiscount: {
      handler() {
        // console.log('watch ticketDiscount change ' + this.ticketDiscount);
        let payableAmount = this.orderAmount - this.ticketDiscount;
        
        if (this.selectedPoint?.usingPoints) {
          // console.log('payable:' + payableAmount)
          let selected = { ...this.selectedPoint };
          let maxPoints =
            (payableAmount / selected.pointsToCurrency.currency) *
            selected.pointsToCurrency.points;
          maxPoints = Math.ceil(Math.max(maxPoints, 0));
          // console.log('maxPoints:' + maxPoints)
          if (maxPoints <= 0) {
            this.$store.commit("updateSelectedPoint", null);
          } else if (selected.usingPoints > maxPoints && maxPoints > 0) {
            // else if (selected.usingPoints > maxPoints && maxPoints > 0) {
            // 如果先選擇選用票券，再停止使用票券，不會自動把點數設定為最大可用點數
            // 如要要支援選擇選用票券，再停止使用票券時，自動設定點數
            // 那曾經手動調整點數的，也會被設定為最大可用點數
            selected.usingPoints = maxPoints;
            this.$store.commit("updateSelectedPoint", selected);
          }
        }
      },
    },
  },
  methods: {
    init() {
      // this.getShopSupportedPoints();
      this.initUserPointAndCoupons();
      // if (this.userId) {
      //   this.getUserPoints();
      // } else {
      //   // this.getDummyShopPoints();
      //   this.getDummyUserPoints();
      // }
    },
    getDummyShopPoints() {
      console.log("getDummyUserPoints");
      const points = [
        {
          merchantId: "tycard2022",
          pointCode: "1",
          pointName: "桃園紅桃",
          pointLogo: "",
          pointsToCurrency: { points: 10, currency: 1 },
          entityType: "Store",
          entityId: "100",
          storeId: 100,
        },
        {
          merchantId: "tycard2022",
          pointCode: "2",
          pointName: "Line Point",
          pointLogo: require("@/assets/img/linepoint.png"),
          pointsToCurrency: { points: 10, currency: 1 },
          entityType: "Store",
          entityId: "100",
          storeId: 100,
          pointStyle: {
            // optional
            background: "#C53A71",
          },
        },
        {
            "pointCode": "8",
            "pointBalance": 10940,
            "dollars": 1094,
            "pointsToCurrency": {
                "points": 10,
                "currency": 1
            },
            "redeemType": "system",
            "merchantId": "ttpush",
            "pointName": "台東金幣",
            "options": "{\"ttpushUserId\":\"1057141423\"}"
        }
      ];
      this.$store.commit("updateSupportedPoints", points);
    },
    getDummyUserCoupons() {
      console.log("[Discounts] getDummyUserCoupons");
      const coupons = [
        {
          merchantId: "dotdot",
          saleOrderId: "231781835087573",
          productId: 10,
          name: "冰淇淋兌換券",
          title: "冰淇淋兌換券",
          description:
            "<p>持本券到任一點點付展示攤位，即可與工作人員兌換冰淇淋一盒</p>",
          restriction:
            "<ol>\r\n<li>請直接於DDPay付款過程中選定使用</li>\r\n</ol>",
          images: [
            "https://storage.googleapis.com/ddc-stamp-images-dev/default-assets/ice-cream.jpeg",
          ],
          storeId: 100,
          storeName: "測試-點點店",
          storeCategoryId: 1,
          storeCategory: "餐飲",
          productType: "coupon",
          provider: null,
          useStartAt: null,
          useExpirationAt: null,
          orderCreatedTs: 1687862108,
          tickets: [
            {
              ticketId: 865,
              barcodeMessage: "231781835087573004",
              status: "pickedup",
              createdTs: 1687862108,
              updatedTs: null,
              quotation: 15,
            },
            {
              ticketId: 900,
              barcodeMessage: "231801455002675000",
              status: "pickedup",
              createdTs: 1688021700,
              updatedTs: null,
              quotation: 15,
            },
            {
              ticketId: 901,
              barcodeMessage: "231801512519964000",
              status: "pickedup",
              createdTs: 1688022771,
              updatedTs: null,
              quotation: 15,
            },
          ],
        },
      ];
      this.$store.commit("updateUserCoupons", coupons);
    },
    getDummyUserPoints() {
      console.log("[Discounts] getDummyUserPoints");
      const points = [
        {
          merchantId: "tycard2022",
          pointCode: "1",
          pointName: "桃園紅桃",
          pointBalance: 1472,
          entityType: "Store",
          entityId: "100",
          storeId: 100,
          pointsToCurrency: {
            points: 10,
            currency: 1,
          },
        },
        {
            "pointCode": "8",
            "pointBalance": 10940,
            "dollars": 1094,
            "pointsToCurrency": {
                "points": 10,
                "currency": 1
            },
            "redeemType": "system",
            "merchantId": "ttpush",
            "pointName": "台東金幣",
            "options": "{\"ttpushUserId\":\"1057141423\"}"
        },
        {
          merchantId: "tycard2022",
          pointCode: "3",
          pointName: "點點點",
          pointBalance: 10,
          entityType: "Store",
          entityId: "100",
          storeId: 100,
          pointsToCurrency: {
            points: 1,
            currency: 1,
          },
        },
      ];
      this.$store.commit("updateUserPoints", points);
      this.setDefaultPoint();
      // if (this.availablePointTypes.length) {
      //   this.onSelectPoint(this.availablePointTypes[0])
      //   // this.$emit('selected', this.availablePointTypes[0]);
      // }
    },
    getShopSupportedPoints() {
      console.log("[Discounts] call api to get supported points");
      // this.$store.commit('updateSupportedPoints', points);
      fetchSupportedPoints(this.shopId)
        .then((response) => {
          console.log(
            "done fetchSupportedPoints (store): " + JSON.stringify(response)
          );

          if (response.data && response.data.points) {
            const pointsUniqueByKey = [
              ...new Map(
                response.data.points.map((item) => [item['pointId'], item])
              ).values(),
            ];
            // console.log(
            //   "unique supported points:" + JSON.stringify(pointsUniqueByKey)
            // );
            this.$store.commit("updateSupportedPoints", pointsUniqueByKey);
            // this.$store.commit('updateSupportedPoints', response.data.points);
          } else {
            this.$store.commit("updateSupportedPoints", []);
          }
        })
        .catch((e) => {
          console.error("fetchSupportedPoints failed (store)", e.response);
          this.$store.commit("updateSupportedPoints", []);
        })
        .finally(() => {
          // this.getDummyUserPoints();
          // this.getDummyUserCoupons();
        });
    },
    initUserPointAndCoupons() {
      console.log('initUserPointAndCoupons')
      if (this.userId || this.thirdPartyMember?.uuid) {
        this.getUserPoints();
        this.getUserCoupons();
      } 
      // else {
      //   this.getDummyShopPoints();
      //   this.getDummyUserPoints();
      //   this.getDummyUserCoupons();
      // }
    },
    getUserCoupons() {
      console.log("call api to get user coupons");

      fetchUserCoupons(this.partnerCode, this.merchantId, this.userId, {
        "amount-to-quote": this.orderAmount,
        "shop-id": this.shopId
      })
        .then((response) => {
          console.log("done fetchUserCoupons: " + JSON.stringify(response));
          if (response.data) {
            this.$store.commit("updateUserCoupons", response.data);
          } else {
            this.$store.commit("updateUserCoupons", []);
          }
        })
        .catch((e) => {
          console.error("fetchUserCoupons failed", e.response);
          this.$store.commit("updateUserCoupons", []);
        });
    },

    getUserPoints() {
      console.log("call api to get user points");

      fetchUserPoints(this.partnerCode, this.merchantId, this.userId, { shop_id: this.shopId })
        .then((response) => {
          console.log("done fetchUserPoints: " + JSON.stringify(response));
          if (response.data) {
            this.$store.commit("updateUserPoints", response.data);
            // 預設第一個，之後看設定決定
            this.setDefaultPoint();
            // if (this.availablePointTypes.length) {
            //   this.$emit("select", this.availablePointTypes[0]);
            // }
          } else {
            this.$store.commit("updateUserPoints", []);
          }
        })
        .catch((e) => {
          console.error("fetchUserPoints failed", e.response);
          this.$store.commit("updateUserPoints", []);
        });
    },
    setDefaultPoint() {
      console.debug("[Discounts] setDefaultPoint:", this.availablePointTypes);
      const defaultUse = this.availablePointTypes.find((p) => p.defaultUse);
      let selected = null;

      if (!this.selectedPoint) {
        if (defaultUse) {
          selected = defaultUse;
        } else if (this.availablePointTypes.length) {
          selected = this.availablePointTypes[0];
        }
        this.$store.commit("updateSelectedPoint", selected);
      }
    },
    // setDefaultTicket() {
    //   console.debug("[Discounts] setDefaultTicket:", this.u);
    //   const defaultUse = this.userCoupons?.length ? this.userCoupons[0] : null

    //   if (defaultUse) {
    //     this.$store.commit("updateSelectedCoupon", this.defaultUse);
    //   }
    // },
    // getCouponQuotation(coupon) {
    //   let queryString = {
    //     "amount-to-quote": this.orderAmount,
    //   };
    //   fetchCouponQuotation(
    //     this.merchantId,
    //     this.userId,
    //     coupon.ticketId,
    //     queryString
    //   )
    //     .then((response) => {
    //       let discount = this.orderAmount - response.data.quotation;
    //       this.$store.commit("updateDiscounts", [
    //         {
    //           name: coupon.name,
    //           productId: coupon.productId,
    //           ticketId: coupon.ticketId,
    //           amount: discount,
    //         },
    //       ]);
    //     })
    //     .catch((e) => {
    //       console.log("failed to get coupon quotation", e);
    //     });
    // },
    onPointsPopupClose(payload) {
      console.log("[Discounts] event:", payload);
      this.showPoints = false;
    },
    onCouponsPopupClose(payload) {
      console.log("[Discounts] event:", payload);
      this.showCoupons = false;
    },
    onError(payload) {
      this.$emit('error', payload)
    }
  },
};
</script>
